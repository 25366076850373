import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Page1711Style.css';
import ApiSender from '../common/ApiLogic';

function Page1711({ uniqid }) {

  const [goalId, setGoalId] = useState(null);
  const [goalName, setGoalName] = useState(null);
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = new URLSearchParams(location.search);
  const paramsObject = {};
  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  useEffect(() => {
    if (queryParams.has("campaignId")) {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_GOOGLE_BASE_URL + 'campaign/info?uuid=' + queryParams.get("campaignId"),
            {
              method: 'GET',
              headers: {
                "Content-Type": "application/json"
              }
            });
          if (response.ok) {
            const data = await response.json();
            const status = data.status;
            if (status) {
              setGoalId(data.goalId);
              setGoalName(data.goalName);
            }
          }
        }
        catch (error) {
        }
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    saveHistory();
  }, []);

  const saveHistory = async () => {
    const apiSender = new ApiSender();
    await apiSender.saveHistory(window.location.origin + location.pathname, paramsObject);
  }

  const handleSubscribe = async () => {
    const gbraid = queryParams.get("gbraid") ? queryParams.get("gbraid") : null;
    const wbraid = queryParams.get("wbraid") ? queryParams.get("wbraid") : null;
    const apiSender = new ApiSender();
    await apiSender.saveHistoryHe(uniqid, paramsObject, gbraid, wbraid, goalId, goalName, window.location.origin + location.pathname, "LP 1711");
  }

  return (
    <div className="Aa1711">
      <div className='container'>
        <div className='form-content'>
          <div className='form-content-top'>
            <div className='logo'><img alt='' src='%PUBLIC_URL%/../../bck.jpeg' /></div>
            <div className='title'>
              <h4>محتوى خاص و مميز</h4>
            </div>
          </div>
          <div className='form-content-middle'>
            <div className='title'>
              <h4 id='title'>اضغط قبل فوات الآوان</h4>
            </div>
            <div className='button'>
              <button id='subscribe-btn' style={{ display: 'block' }} onClick={handleSubscribe}>شاهد الآن <span class="arrow">&raquo;</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page1711;