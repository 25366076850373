import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './LandingPageContent.css';
import { format } from 'date-fns';

function LandingPagePinFlow() {

//const gatewayBaseUrl = "http://localhost:8002";
const gatewayBaseUrl = "https://iraqgtw.kid-security.io";

const [goalId, setGoalId] = useState(null);
const [goalName, setGoalName] = useState(null);

useEffect(() => {
  if (queryParams.has("campaignId")) {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GOOGLE_BASE_URL + 'campaign/info?uuid=' + queryParams.get("campaignId"),
          {
            method: 'GET',
            headers: {
              "Content-Type": "application/json"
            }
          });
        if (response.ok) {
          const data = await response.json();
          const status = data.status;
          if (status) {
            setGoalId(data.goalId);
            setGoalName(data.goalName);
          }
        }
      }
      catch (error) {
      }
    }
    fetchData();
  }
}, []);

//OTHER
const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
const [isImageVisible, setIsImageVisible] = useState(true);
const handleButtonClick = () => {
  setIsGradientRightToLeft(!isGradientRightToLeft);
  setIsImageVisible(!isImageVisible);
};
const gradientStyle = {
  background: isGradientRightToLeft
    ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
    : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
};


const closeOtp = () => {
  document.getElementById('myOtp').style.display = 'none';
  clearOtpInput();
};

const clearOtpInput = () => {
  const otpElements = document.querySelectorAll('[id^="otp-input-"]');
    otpElements.forEach((element) => {
      element.value = '';
      element.style.border = '';
  });
  setOtp([null, null, null, null, null]);
  document.getElementById("verifyResult").textContent = '';
  document.getElementById('otp-input-0').focus();
}

const popupRef = useRef(null);
useEffect(() => {
  const handleClickOutside = (event) => 
  {
    if (popupRef.current && !popupRef.current.contains(event.target)) 
    {
      // closeOtp();
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

//VARIABLES
const [msisdn, setMsisdn] = useState(null);
const getMsisdn = (event) => {
  setMsisdn(event.target.value);
  document.getElementById("msisdn").style.border = '';
}
const [otp, setOtp] = useState([null, null, null, null, null]);
const handleInputChange = (index, value) => {
  const newOtp = [...otp];
  newOtp[index] = value;
  if (index < otp.length - 1 && value !== '') {
    document.getElementById(`otp-input-${index + 1}`).focus();
  }
  setOtp(newOtp);
};
const location = useLocation();
const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
const [campaignId, setCampaignId] = useState(null);
const [trackerId, setTrackerId] = useState(null);
const [uniqid, setUniqid] = useState(null);
const [google, setGoogle] = useState(null);
const urlParams = new URLSearchParams(location.search);
const params = {};
for (const [key, value] of urlParams.entries()) {
  params[key] = value;
}
useEffect(() => {
  if(queryParams.get("campaignId"))
  {
    setCampaignId(queryParams.get("campaignId"));
  }
  if(queryParams.get("trackerId"))
  {
    setTrackerId(queryParams.get("trackerId"));
  }
  if(queryParams.get("uniqid"))
  {
    setUniqid(queryParams.get("uniqid"));
  }
  if(queryParams.get("gclid"))
  {
    setGoogle(queryParams.get("gclid"));
  }
}, [queryParams]);

//SEND PIN FLOW
const openOtpAndRequestPin = async () => 
{ 
  if(msisdn === null || msisdn === undefined || msisdn.length !== 10)
  {
    document.getElementById("msisdn").style.border = "2px solid #AA4098";
  }
  else
  {
    //open OTP popup
    document.getElementById("msisdn").style.border = '';
    document.getElementById("sendResult").textContent = '';
    document.getElementById("verifyResult").textContent = '';
    //start calling send PIN api
    try 
    {
      const response = await fetch(gatewayBaseUrl + '/send-pin/' + msisdn, 
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        }        
      });
      if(response.ok) 
      {
        const result = await response.json();
        if(result.code === 2000)
        {
          document.getElementById('myOtp').style.display = 'block';
        }
        else
        {
          document.getElementById('sendResult').textContent = result.message;
        }
      }
      else
      {
        const result = await response.json();
        document.getElementById('myOtp').style.display = 'none';
        document.getElementById('sendResult').textContent = result.message;
        if(result.message !== null && result.message.includes("24 Hours Rule"))
          {
            if(isImageVisible)
            {
              document.getElementById("sendResult").textContent = "عزيزي المشترك يرجى المحاولة بعد 24 ساعة";
            }
          }
      }
    } 
    catch (error) 
    {
      document.getElementById('myOtp').style.display = 'none';
      document.getElementById('sendResult').textContent = "Send PIN Failed";
    }
  }
};
 
//VERIFY PIN FLOW
const verifyPin = async () => 
{
  if(otp.join('').length !== 5)
  {
    const otpElements = document.querySelectorAll('[id^="otp-input-"]');
      otpElements.forEach((element) => {
        element.style.border = "2px solid #AA4098";
      });
  }
  else
  {
    document.getElementById("sendResult").textContent = '';
    document.getElementById("verifyResult").textContent = '';
    //start calling verify PIN api
    try 
    {
      const date = new Date();
      const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
      const gbraid = queryParams.get("gbraid") ? queryParams.get("gbraid") : null;
      const wbraid = queryParams.get("wbraid") ? queryParams.get("wbraid") : null;
  
      const response = await fetch(gatewayBaseUrl + '/verify-pin', 
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          msisdn: msisdn,
          pin: otp.join(''),
          campaignId:  campaignId,
          trackerId: trackerId,
          transactionId: uniqid,
          googleTrackerId: google,
          gbraid: gbraid,
          wbraid: wbraid,
          conversionDateTime: formattedDateTime,
          conversionGoalId: goalId,
          conversionGoalName: goalName,
          landingPageUrl: window.location.origin + location.pathname,
          landingPageName: "KS Main LP",  
          p1: params.p1,
          p2: params.p2,
          p3: params.p3,
          p4: params.p4,
          p5: params.p5,
          p6: params.p6,
          p7: params.p7,
          p8: params.p8,
          p9: params.p9,
          p10: params.p10
        }) 
      });
      if(response.ok) 
      {
        const result = await response.json();
        if(result.code === 2000)
        {
          closeOtp();
          document.getElementById("sendResult").textContent = result.message;
          redirectToSuccess();
        }
        else
        {
          document.getElementById("verifyResult").textContent = result.message;
          // redirectToFailed();
        }
      }
      else
      {
        const result = await response.json();
        document.getElementById("verifyResult").textContent = result.message;
        if(result.message !== null && result.message.includes("24 Hours Rule"))
        {
          if(isImageVisible)
          {
            document.getElementById("verifyResult").textContent = "عزيزي المشترك يرجى المحاولة بعد 24 ساعة";
          }
        }
        // redirectToFailed();
      }
    } 
    catch (error) 
    {
      document.getElementById("verifyResult").textContent = 'Verify PIN Failed';
      // redirectToFailed();
    }
  }
};

const redirectToSuccess = () => {
  const redirectionUrl = 'https://iraq.kid-security.io/success';
  window.location.href = redirectionUrl;
};

const redirectToFailed = () => {
  const redirectionUrl = 'https://iraq.kid-security.io/failed';
  window.location.href = redirectionUrl;
};

  return (
   
      <div className="App">
      
      <div className="upside" >
          <div className="header">
                <div className="logo">
                  {
                    isImageVisible ? ( <img alt='' src='%PUBLIC_URL%/../../kid-arabic.png'/> ) : ( <img alt='' src='%PUBLIC_URL%/../../kid-english.png'/> )
                  }
                </div>
                <div className="language" style={gradientStyle} onClick={handleButtonClick}>
                  <p>عر</p>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
                  <p>en</p>
                </div>
          </div>

          <div className="title">
            {
              isImageVisible ? ( <h1>امنح أطفالك الحرية الرقمية الآمنة: مع خدمة امان الاطفال، عالم رقمي مشرق لأطفالك</h1> ) : ( <h1>Give your kids safe digital freedom: With Kid Security, a bright digital world for your kids</h1> )
            }
            <div className="photo">
              <img alt='' src='%PUBLIC_URL%/../../kid-security.png'/> 
            </div>
          </div>
      </div>

      

      <div className="content">
        <div className="input">
          {
            isImageVisible ? ( <p>املأ رقم هاتفك المحمول</p> ) : ( <p>Fill Your Mobile Number</p> )
          }
          <div className="textfield">
            <div className="cc">+964</div>
            <input id="msisdn" value={msisdn} placeholder="7xxxxxxxxx" type="tel" maxLength="10" onChange={getMsisdn} autoComplete="off"/>
          </div>
          {
            isImageVisible ? ( <button id="pin" onClick={openOtpAndRequestPin}>إشترك</button> ) : ( <button id="pin" onClick={openOtpAndRequestPin}>Subscribe</button> )
          }
          <p id="sendResult" className="terms" style={{fontWeight:"bold"}}></p>
        </div>
        <div className="terms" style={{direction:"rtl"}}>
          {
            isImageVisible ? (<p>سيحصل المستخدمون لأول مرة على تجربة مجانية ليوم واحد سعر الخدمة 300 IQD للرسالة المستلمة في اليوم. لإلغاء الإشتراك أرسل UKS الى 3368</p>) : (<p>Zain Subscribers will enjoy 1 day free trial. Service price is 300 IQD Daily. To unsubscribe send UKS to 3368</p>)
          }
        </div>
      </div>

      <div className="popup" id="myOtp" ref={popupRef}>
        <div className="popup-content">
          <span className="close" id="closeOtp" onClick={closeOtp}>&times;</span>
          <div className="otp-input-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="tel"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="otp-input"
                autoComplete="off"
              />
            ))}
          </div>
          <div className="verify">
            {
              isImageVisible ? ( <button id="verify" onClick={verifyPin}>التحقق من الرمز السري</button> ) : ( <button id="verify" onClick={verifyPin}>Verify PIN</button> )
            }
            <p id="verifyResult" className="terms" style={{fontWeight:"bold"}}></p>
          </div>
        </div>
      </div>

    </div>
    
  );
}

export default LandingPagePinFlow;